import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell de fins a 25 cm de diàmetre, primer hemisfèric i després convex de color clar a blanc brut amb zones rosades, sobretot al marge. Els tubs són llargs, grocs i verdosos si són més madurs. Els porus són petits, grocs o ataronjats, tornant-se blau verdosos quan els toquem. Les espores són de color ocre olivaci, fusiformes, de 12-18 x 6-8 micres. El peu és gruixut, presenta un reticle fi i és de color crema i té una tonalitat més rosada cap a la meitat.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      